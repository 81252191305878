import { createSlice } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

import { actionTypes as sessionActionTypes } from 'actions/session';

import {
  fetchFeatures,
  fetchProducts,
  fetchProductTipManager,
  fetchTipManagerSubscriptions,
} from './actions';
import { getBillingCycleIsAnnual } from './util';

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    monthlyProducts: [],
    annualProducts: [],
    features: [],
    // TODO: refactor to use a single object for all products categorized by addons and plans
    tipManager: {
      isSubscribed: false,
      price: { amount: null, priceSystemId: '' },
    },
  },
  reducers: {
    toggleBillingFrequency: (state, { payload }) => {
      state.isSelectedBillingCycleAnnual = payload === 'annual';
    },
  },
  extraReducers: {
    [fetchProducts.pending]: state => {
      state.monthlyProducts = [];
      state.annualProducts = [];
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.monthlyProducts = action.payload.monthly;
      state.annualProducts = action.payload.annual;
      state.isSelectedBillingCycleAnnual = getBillingCycleIsAnnual(
        action.payload
      );
    },
    [sessionActionTypes.UPDATE_SESSION]: (state, action) => {
      const { tierProducts } = action.payload;
      if (!tierProducts) return state;

      state.monthlyProducts = tierProducts.monthly;
      state.annualProducts = tierProducts.annual;
      state.isSelectedBillingCycleAnnual =
        getBillingCycleIsAnnual(tierProducts);
    },
    [fetchFeatures.pending]: state => {
      state.features = [];
    },
    [fetchFeatures.fulfilled]: (state, action) => {
      state.features = action.payload.features;
    },
    [fetchTipManagerSubscriptions.fulfilled]: (state, action) => {
      const activeSubscription = action.payload.subscriptions.find(
        subscription => isNull(subscription?.archived_at)
      );
      if (activeSubscription) {
        state.tipManager.price.amount = activeSubscription.price_in_cents / 100;
      }
      state.tipManager.isSubscribed = Boolean(activeSubscription);
    },
    [fetchProductTipManager.fulfilled]: (state, action) => {
      // Will be empty if already subscribed
      if (!action.payload.products?.[0].price?.length) return;

      // TODO: should convert price to prices on the BE, ask billing team to fix
      const price = action.payload.products[0].price[0];
      state.tipManager.price.amount = price.amount_in_cents / 100;
      state.tipManager.price.priceSystemId = price.system_id;
    },
  },
});

export const actions = productsSlice.actions;
export const reducer = productsSlice.reducer;
